<template>
  <div class="container">
    <div class="form-card mb-20">
      <div class="card-head">
        <h4 class="card-title">
          <span>Müşteri Detay</span>
          <i class="icon icon-arrow-right-3"></i>
          {{ formData.name }}
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="Ad"
              :value="formData.name"
              keyName="formData.name"
              type="text"
              :className="v$.formData.name.$errors.length > 0 ? 'error' : ''"
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.name.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="E-mail"
              :value="formData.contact_email"
              keyName="formData.contact_email"
              type="text"
              :className="
                v$.formData.contact_email.$errors.length > 0 ? 'error' : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.contact_email.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <template v-if="false">
            <div class="col-md-4">
              <div class="form-group">
                <label for="">İl</label>
                <Multiselect
                  v-model="formData.city"
                  :options="cityList"
                  placeholder=""
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                ></Multiselect>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">İlçe</label>
                <Multiselect
                  v-model="formData.town"
                  :options="townList"
                  placeholder=""
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                ></Multiselect>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Mahalle</label>
                <Multiselect
                  v-model="formData.neighborhood"
                  :options="neighborhoodList"
                  placeholder=""
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                ></Multiselect>
              </div>
            </div>
            <div class="col-md-4">
              <TextTemplate
                label="Posta Kodu"
                :value="formData.zip_code"
                keyName="formData.zip_code"
                type="text"
                @onChangeText="handleText"
              />
            </div>
            <div class="col-md-12">
              <TextTemplate
                label="Adres"
                :value="formData.address"
                keyName="formData.address"
                type="textarea"
                @onChangeText="handleText"
              />
            </div>
            <div class="col-md-4">
              <TextTemplate
                label="Vergi No"
                :value="formData.tax_number"
                keyName="formData.tax_number"
                type="text"
                @onChangeText="handleText"
              />
            </div>
            <div class="col-md-4">
              <TextTemplate
                label="Vergi Dairesi"
                :value="formData.tax_office"
                keyName="formData.tax_office"
                type="text"
                @onChangeText="handleText"
              />
            </div>
          </template>
        </div>
      </div>
      <button
        class="btn btn-filled-primary mt-20 ml-auto"
        @click="updateCustomer"
      >
        Güncelle
      </button>
    </div>
    <div class="row mb-20">
      <div class="col-md-6">
        <div class="form-card">
          <div class="card-body">
            <h4 class="card-sm-title">Apple Developer Hesabı</h4>
            <TextTemplate
              label="Ad"
              :value="apple_developer_accounts.name"
              keyName="apple_developer_accounts.name"
              type="text"
              :className="
                v$.apple_developer_accounts.name.$errors.length > 0
                  ? 'error'
                  : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.apple_developer_accounts.name.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
            <TextTemplate
              label="Kullanıcı Adı"
              :value="apple_developer_accounts.account_user_name"
              keyName="apple_developer_accounts.account_user_name"
              type="text"
              :className="
                v$.apple_developer_accounts.account_user_name.$errors.length > 0
                  ? 'error'
                  : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.apple_developer_accounts.account_user_name
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
            <TextTemplate
              label="Şifre"
              :value="apple_developer_accounts.account_password"
              keyName="apple_developer_accounts.account_password"
              type="text"
              :className="
                v$.apple_developer_accounts.account_password.$errors.length > 0
                  ? 'error'
                  : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.apple_developer_accounts.account_password
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
            <label for="">enrolled_as</label>
            <div class="d-flex">
              <div class="form-check mb-15 mr-30">
                <input
                  class="form-check-input"
                  type="radio"
                  v-model="apple_developer_accounts.enrolled_as"
                  value="individual"
                  id="individual"
                />
                <label class="form-check-label" for="individual">
                  individual
                </label>
              </div>
              <div class="form-check mb-15">
                <input
                  class="form-check-input"
                  type="radio"
                  v-model="apple_developer_accounts.enrolled_as"
                  value="organization"
                  id="organization"
                />
                <label class="form-check-label" for="organization">
                  organization
                </label>
              </div>
            </div>
            <span
              v-for="error in v$.apple_developer_accounts.enrolled_as.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
        </div>
        <button
          @click="setDeveloper('apple', 'patch')"
          class="btn btn-filled-primary mt-20 ml-auto"
          v-if="apple_developer_accounts.id"
        >
          <span>Güncelle</span>
        </button>
        <button
          @click="setDeveloper('apple')"
          class="btn btn-filled-primary mt-20 ml-auto"
          v-else
        >
          <span>Ekle</span>
        </button>
      </div>
      <div class="col-md-6">
        <div class="form-card">
          <div class="card-body">
            <h4 class="card-sm-title">Google Developer Hesabı</h4>
            <TextTemplate
              label="Ad"
              :value="google_developer_accounts.name"
              keyName="google_developer_accounts.name"
              type="text"
              :className="
                v$.google_developer_accounts.name.$errors.length > 0
                  ? 'error'
                  : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.google_developer_accounts.name.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
            <TextTemplate
              label="Kullanıcı Adı"
              :value="google_developer_accounts.account_user_name"
              keyName="google_developer_accounts.account_user_name"
              type="text"
              :className="
                v$.google_developer_accounts.account_user_name.$errors.length >
                0
                  ? 'error'
                  : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.google_developer_accounts.account_user_name
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
            <TextTemplate
              label="Şifre"
              :value="google_developer_accounts.account_password"
              keyName="google_developer_accounts.account_password"
              type="text"
              :className="
                v$.google_developer_accounts.account_password.$errors.length > 0
                  ? 'error'
                  : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.google_developer_accounts.account_password
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
        </div>
        <button
          @click="setDeveloper('google', 'patch')"
          class="btn btn-filled-primary mt-20 ml-auto"
          v-if="google_developer_accounts.id"
        >
          <span>Güncelle</span>
        </button>
        <button
          @click="setDeveloper('google')"
          class="btn btn-filled-primary mt-20 ml-auto"
          v-else
        >
          <span>Ekle</span>
        </button>
      </div>
    </div>
  </div>
  <Done
    @closeModal="doneModalClose"
    v-if="isCloseModal"
    :doneMsg="popupText"
    :doneTitle="popupTitle"
    link="/customers"
  />
</template>
<script>
import useValidate from "@vuelidate/core";
import { helpers, required, email } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useValidate(),
      isCloseModal: false,
      popupText: "",
      popupTitle: "",
      formData: {
        name: "",
        contact_email: "",
        city: "",
        town: "",
        neighborhood: "",
        zip_code: "",
        address: "",
        tax_number: "",
        tax_office: "",
      },

      apple_developer_accounts: {
        name: "",
        account_user_name: "",
        account_password: "",
        enrolled_as: "",
      },
      google_developer_accounts: {
        name: "",
        account_user_name: "",
        account_password: "",
      },

      cityList: ["İstanbul"],
      townList: ["Bağcılar"],
      neighborhoodList: ["Barbaros Mah."],
      customerId: this.$route.params.id,
      manipulatedData: {},
    };
  },
  validations() {
    return {
      formData: {
        name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        contact_email: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          email: helpers.withMessage(
            "Lütfen geçerli bir e-mail giriniz.",
            email
          ),
          $autoDirty: true,
        },
      },
      apple_developer_accounts: {
        name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        account_user_name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        account_password: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        enrolled_as: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
      },
      google_developer_accounts: {
        name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        account_user_name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        account_password: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
      },
    };
  },

  async created() {
    this.getCustomer();
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Müşteriler",
        path: "/customers",
      },
      {
        title: "Müşteri Detay",
      },
      {
        title: `${this.$route.params.id}`,
      },
    ]);
  },
  methods: {
    doneModalClose(value) {
      this.isCloseModal = value;
    },
    setDeveloper(platform, action) {
      let data;

      if (platform == "apple") {
        this.v$.apple_developer_accounts.$touch();
        if (!this.v$.apple_developer_accounts.$error) {
          data = {
            apple_developer_accounts: [{ ...this.apple_developer_accounts }],
          };
        }
      } else {
        this.v$.google_developer_accounts.$touch();
        if (!this.v$.google_developer_accounts.$error) {
          data = {
            google_developer_accounts: [{ ...this.google_developer_accounts }],
          };
        }
      }

      if (action == "patch") {
        if (data) {
          this.$axios
            .put(
              `/developer-account/${platform}/${
                data[`${platform}_developer_accounts`][0].id
              }/`,
              data.apple_developer_accounts[0]
            )
            .then((response) => {
              this.getCustomer();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else if (data) {
        this.$axios
          .patch(`/customer/${this.customerId}/`, data)
          .then((response) => {
            this.getCustomer();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    updateCustomer() {
      this.v$.formData.$touch();
      if (!this.v$.formData.$error) {
        let postData = JSON.parse(
          JSON.stringify(this.manipulatedData.formData)
        );
        this.$axios
          .patch(`/customer/${this.customerId}/`, postData)
          .then(() => {
            this.manipulatedData = {};
            this.popupText = "Müşteri Güncellendi";
            this.popupTitle = "Müşteri Güncelleme";
            this.isCloseModal = true;
          });
      }
    },
    getCustomer() {
      this.$axios
        .get(`/customer/${this.customerId}/`)
        .then(async (response) => {
          let customer = response.data;
          this.formData = {
            name: customer.name,
            contact_email: customer.contact_email,
            city: customer.city,
            town: customer.town,
            neighborhood: customer.neighborhood,
            zip_code: customer.zip_code,
            address: customer.address,
            tax_number: customer.tax_number,
            tax_office: customer.tax_office,
            apple_developer_accounts: customer.apple_developer_accounts,
            google_developer_accounts: customer.google_developer_accounts,
          };
          this.apple_developer_accounts =
            customer.apple_developer_accounts.length > 0
              ? customer.apple_developer_accounts[0]
              : {
                  name: "",
                  account_user_name: "",
                  account_password: "",
                  enrolled_as: "",
                };
          this.google_developer_accounts =
            customer.google_developer_accounts.length > 0
              ? customer.google_developer_accounts[0]
              : {
                  name: "",
                  account_user_name: "",
                  account_password: "",
                };
        });
    },

    handleText(data) {
      let keynames = data.keyName.split(".");
      let targetn = this.manipulatedData;

      if (keynames.length <= 1) {
        this[keynames[0]] = data.value;
        targetn[keynames[0]] = data.value;
      } else {
        this[keynames[0]][keynames[1]] = data.value;
        targetn[keynames[0]] = {};
        targetn[keynames[0]][keynames[1]] = data.value;
      }
    },
  },
};
</script>